import React, {useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import ActionCreator from '../ActionCreator';
import {StaticImage} from '../Components/StaticImage';
import * as AppContext from '../Contexts/AppContext';
import {NavActions} from '../Contexts/AppContext';

const brands = [
  {key: 'kenzo', img: 'logo-kenzo.png', route: '/kenzo'},
  {
    key: 'self-portrait',
    img: 'logo-self-portrait.png',
    route: '/self-portrait',
  },
  {key: 'agete', img: 'logo-agete.png', route: '/agete'},
  {
    key: 'isabel-marant',
    img: 'logo-isabel-marant.png',
    route: '/isabel-marant',
  },
  {key: 'les-nereides', img: 'logo-les-nereides.png', route: '/les-nereides'},
];

function LandingPage(props) {
  const [activeBrand, setActiveBrand] = useState(null);
  const {
    navActions,
    app: {breakpoints},
  } = props;
  return (
    <Wrapper breakpoints={breakpoints}>
      <div className="banner">
        <div className="banner-img-container">
          <StaticImage name="landing.png" style={{height: '100%'}} />
        </div>
        <div className="mask" />
        <div className="center">
          <div className="main-logo">
            <StaticImage path="logos" name="landing-logo.png" />
          </div>
          <div className="description">請選擇您想探索的品牌</div>
          <div className="vertical-line" />
          <div className="sub-logo-wrapper">
            {brands.map((b, idx) => (
              <div
                className={`item${activeBrand === b.key ? ' active' : ''}${
                  activeBrand !== null && activeBrand !== b.key
                    ? ' inactive'
                    : ''
                }`}
                key={`${idx}`}
                onMouseEnter={() => setActiveBrand(b.key)}
                onMouseLeave={() => setActiveBrand(null)}
                onClick={() => {
                  AppContext.Actions.setActiveBrand(b.key);
                  NavActions.navigate(`/home?brand=${b.key}`);
                }}>
                <StaticImage path="logos" name={b.img} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: lightgray;
  --main-tungrp-logo-width: 116px;
  --brand-logo-width: 200px;
  --max-brands-wrapper-width: 1200px;
  --brand-logo-margin-bottom: 0px;
  --vertical-line-margin-bottom: 116px;

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    --main-tungrp-logo-width: 107px;
    --brand-logo-width: 200px;
    --max-brands-wrapper-width: 700px;
    --brand-logo-margin-bottom: 116px;

    & > .banner {
      & > .center {
        & .sub-logo-wrapper {
          & > .item:nth-child(4) {
            padding-bottom: 0px;
          }
          & > .item:nth-child(5) {
            padding-bottom: 0px;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    --main-tungrp-logo-width: 50px;
    --brand-logo-width: 124px;
    --max-brands-wrapper-width: 200px;
    --brand-logo-margin-bottom: 24px;
    --vertical-line-margin-bottom: 56px;

    & > .banner {
      & > .center {
        & .sub-logo-wrapper {
          & > .item:nth-child(4) {
            margin-bottom: var(--brand-logo-margin-bottom);
          }
          & > .item:nth-child(5) {
            margin-bottom: var(--brand-logo-margin-bottom);
          }
        }
      }
    }
  }

  & > .banner {
    height: 100vh;
    background-color: lightgray;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .banner-img-container {
      width: 100%;
      height: 100%;
    }

    & > .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(20, 20, 20, 0.2),
        rgba(20, 20, 20, 0.6)
      );
    }

    & > .center {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .main-logo {
        width: var(--main-tungrp-logo-width);
        margin-bottom: 40px;
      }

      & > .description {
        font-size: 20px;
        color: #fff;
        font-weight: 300;
        line-height: 1.4;
        margin-bottom: 40px;
      }

      & > .vertical-line {
        width: 1px;
        height: 24px;
        background-color: #fff;
        margin-bottom: var(--vertical-line-margin-bottom);
      }

      & > .sub-logo-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: var(--max-brands-wrapper-width);

        & > .item {
          width: var(--brand-logo-width);
          padding: 0px 6px;
          cursor: pointer;
          transition: opacity 0.2s ease-in;
          margin-bottom: var(--brand-logo-margin-bottom);
        }

        & > .item.active {
        }

        & > .item.inactive {
          opacity: 0.4;
        }
      }
    }
  }
`;

export default AppContext.withConsumer(
  connect(null, ActionCreator)(LandingPage),
);
